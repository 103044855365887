import React, { useEffect } from 'react'
import DecimalFormat from 'decimal-format'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Service = ({
	index,
	product,
	listItemVariantFromLefts,
	displayInline,
	inViewThreshold,
	productCount,
	categoryName,
}) => {
	const df = new DecimalFormat('#,##0.00')

	const controls = useAnimation()
	const { ref, inView } = useInView({
		threshold: inViewThreshold ? inViewThreshold : 0.2,
	})

	useEffect(() => {
		if (inView) {
			controls.start('visible')
		}
	}, [controls, inView])

	const {
		// displayOrder,
		priceWithHairCut,
		priceWithHairCutMax,
		// productDescription,
		productName,
		productPrice,
		priceRangeMax,
	} = product

	const itemVariantFromLeft = {
		hidden: { opacity: 0, x: 300 },
		visible: { opacity: 1, x: 0 },
	}

	useEffect(() => {
		console.log('product = ', product)
	}, [product])

	return (
		<>
			<motion.li
				className="service__item"
				// style={{ order: displayOrder }}
				ref={ref}
				variants={itemVariantFromLeft}
				animate={{}}
				transition={{ staggerChildren: 0.2 }}
			>
				<motion.div
					className="service__item__price-standard service__item__price-row"
					variants={itemVariantFromLeft}
					initial="hidden"
					animate={controls}
				>
					<motion.div className="service__item__price-standard__name">
						{productName}
						{productName === 'Uberliss Keratin Straightening Treatment' && (
							<div className="consult-req">(Consultation required)</div>
						)}
					</motion.div>
					{productPrice && productName != 'Uberliss Keratin Straightening Treatment' && (
						<motion.div className="service__item__price-standard__price">
							${df.format(productPrice)}
							{priceRangeMax && <> - ${df.format(priceRangeMax)}</>}
						</motion.div>
					)}
					{productName === 'Uberliss Keratin Straightening Treatment' && (
						<motion.div className="service__item__price-standard__price">
							starting at ${df.format(productPrice)}
						</motion.div>
					)}
				</motion.div>
				{priceWithHairCut && (
					<motion.div
						className="service__item__price-with-cut service__item__price-row"
						variants={itemVariantFromLeft}
						initial="hidden"
						animate={controls}
						transition={{ duration: 0.4 }}
					>
						<div className="service__item__price-with-cut__text">With Cut</div>
						<div className="service__item__price-with-cut__price">
							${df.format(priceWithHairCut)}
							{priceWithHairCutMax && <> - ${df.format(priceWithHairCutMax)}</>}
						</div>
					</motion.div>
				)}
			</motion.li>
			{displayInline && index < productCount - 1 && (
				<motion.li
					className="service__item divider"
					variants={itemVariantFromLeft}
					initial="hidden"
					animate={controls}
				>
					|
				</motion.li>
			)}
		</>
	)
}

export default Service
