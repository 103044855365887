import React from 'react'
import LayoutMain from '../layout/LayoutMain'
import '../styles/main.css'
import Video from '../components/Video'
import Services from '../components/Services'
import TeamMembers from '../components/TeamMembers'
// eslint-disable-next-line
import FontAwesomeLibrary from '../utils/FontAwesomeLibrary'
import Policies from '../components/Policies'
import SocialFollow from '../components/SocialFollow'

const Home = () => {
	return (
		<LayoutMain>
			<Video />
			<Services />
			<TeamMembers />
			<Policies />
			<SocialFollow />
		</LayoutMain>
	)
}

export default Home
