import React, { useState, useEffect, useRef } from 'react'
import { useWindowWidth, useIsWindowResizing } from '../utils/hooks'

const Video = () => {
	const videoIframeRef = useRef()
	const [iFrameHeight, setIframeHeight] = useState()
	const resizing = useIsWindowResizing()
	const [elemWidth, setElemWidth] = useState()

	const handleIframeLoad = () => {
		setElemWidth(videoIframeRef.current.offsetWidth)
		setIframeHeight(Math.round(elemWidth * 0.565))
	}

	useEffect(() => {
		setElemWidth(videoIframeRef.current.offsetWidth)
	}, [resizing])

	useEffect(() => {
		setIframeHeight(Math.round(elemWidth * 0.565))
	}, [elemWidth])

	return (
		<div className="container container--wide video-container">
			<div className="bg-bar"></div>
			<div className="container container--standard video-wrapper">
				<iframe
					ref={videoIframeRef}
					className="video video--about-beach-break"
					title="About Beach Break Salon"
					src="https://www.youtube.com/embed/WG5WMFx-I1w"
					frameBorder="0"
					onLoad={() => handleIframeLoad()}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					style={{ height: iFrameHeight + 'px' }}
				></iframe>
			</div>
		</div>
	)
}

export default Video
