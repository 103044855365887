import React, { useState, useEffect } from 'react'
import Service from './Service'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

const formatClassName = (item) => {
	return item.split(' ').join('-').toLowerCase()
}

const Category = ({ categoryName, index, displayOrder, products, disclaimer, inViewThreshold }) => {
	const controls = useAnimation()
	const { ref, inView } = useInView({
		threshold: inViewThreshold ? inViewThreshold : 1,
	})

	useEffect(() => {
		if (inView) {
			controls.start('visible')
		}
	}, [controls, inView])

	const categoryVariant = {
		hidden: { opacity: 0, x: 300 },
		visible: { opacity: 1, x: 0 },
	}

	const listItemVariants = {
		hidden: { x: 50, opacity: 0 },
		visible: (custom) => {
			return { x: 0, opacity: 1 }
		},
	}

	const [orderedProducts, setOrderedProducts] = useState()

	useEffect(() => {
		console.log('products = ', products)
		setOrderedProducts(products.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)))
	}, [products])

	return (
		<div
			key={index}
			className={`service__type service__type--${formatClassName(categoryName)}`}
			style={{ order: displayOrder }}
			// ref={ref}
			// variants={categoryVariant}
			// initial="hidden"
			// animate={controls}
			// transition={{ staggerChildren: 0.3 }}
		>
			<h2 className="service__type__title">{categoryName.toUpperCase()}</h2>

			{orderedProducts && (
				<>
					<motion.ul
						// className={`service-list service${
						// 	categoryName === 'Extensions Services' ? ' list--inline' : ''
						// }`}
						className={`service-list service`}
					>
						{orderedProducts.map((product, index2) => {
							return (
								<>
									<Service
										index={index2}
										productCount={orderedProducts.length}
										product={product}
										listItemVariants={listItemVariants}
										categoryName={categoryName}
										// displayInline={
										// 	categoryName === 'Extensions Services' ? true : false
										// }
									/>
									{/* {categoryName === 'Extensions Services' &&
									index2 < orderedProducts.length - 1 && (
										<li className="service__item divider">|</li>
									)} */}
								</>
							)
						})}
						{/* {categoryName === 'Extensions Services' && (
							<div className="category-global-price price-centered service__item__price-standard">
								<div className="service__item"> | $145/hr</div>
							</div>
						)} */}
					</motion.ul>
				</>
			)}
			{/* {categoryName === 'Hair Cuts' && (
				<motion.div className="disclaimer">
					All pricing subject to change based off timing of service.
				</motion.div>
			)} */}
			{disclaimer ? (
				<motion.div
					className="disclaimer"
					dangerouslySetInnerHTML={{ __html: disclaimer.md.html }}
				></motion.div>
			) : (
				''
			)}
		</div>
	)
}

export default Category
