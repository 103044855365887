import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'
import Policy from './Policy'

const Policies = () => {
	const controls = useAnimation()
	const { ref, inView } = useInView({
		threshold: 1,
	})
	const data = useStaticQuery(getPolicies)
	const { allPolicies } = data
	const { policies } = allPolicies
	// console.log('policies = ', policies)

	// const refsArray = []

	const policiesTitleVariant = {
		hidden: { opacity: 0, y: -20 },
		visible: { opacity: 1, y: 0 },
	}

	useEffect(() => {
		if (inView) {
			controls.start('visible')
		}
	}, [controls, inView])

	return (
		<div
			className="container container--standard policies-container"
			// ref={policiesRef}
			// variants={policyVariant}
			// animate={controls}
			// initial="hidden"
		>
			<motion.h2
				ref={ref}
				className="policies-container__title"
				variants={policiesTitleVariant}
				initial="hidden"
				animate={controls}
			>
				Deposits / Policies
			</motion.h2>

			{policies.map((item, index) => {
				const { policy } = item
				const { policyTitle, policyDescription, showPolicyTitle, displayOrder } = policy
				return (
					<Policy
						key={index}
						displayOrder={displayOrder}
						showPolicyTitle={showPolicyTitle}
						policyTitle={policyTitle}
						policyDescription={policyDescription}
						inViewThreshold={0.5}
					/>
				)
			})}
		</div>
	)
}

export const getPolicies = graphql`
	query GetPolicies {
		allPolicies: allContentfulPolicy {
			policies: edges {
				policy: node {
					policyTitle
					showPolicyTitle
					policyDescription {
						md: childMarkdownRemark {
							html
						}
					}
					displayOrder
				}
			}
		}
	}
`

export default Policies
