import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Category from './Category'

const Services = () => {
	const data = useStaticQuery(getProductsAndCategories)
	// console.log('data = ', data)
	const { allCategories } = data
	const { categories } = allCategories

	const [orderedCategories, setOrderedCategories] = useState()

	useEffect(() => {
		console.log('categories = ', categories)
		setOrderedCategories(categories.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)))
	}, [categories])

	useEffect(() => {
		console.log('orderedCategories = ', orderedCategories)
	}, [orderedCategories])

	return (
		<div className="container container--standard services-container">
			<h1>OUR SERVICES</h1>
			{orderedCategories && (
				<div className="service">
					{categories.map((cat, index) => {
						const { category } = cat
						const { name, displayOrder, disclaimer, products } = category

						return products !== null ? (
							<Category
								key={index}
								categoryName={name}
								index={index}
								displayOrder={displayOrder}
								products={products}
								disclaimer={disclaimer}
								inViewThreshold={0}
							/>
						) : null
					})}
				</div>
			)}
		</div>
	)
}

export const getProductsAndCategories = graphql`
	query GetProductsAndCategories {
		allCategories: allContentfulProductCategory {
			categories: edges {
				category: node {
					name
					displayOrder
					disclaimer {
						md: childMarkdownRemark {
							html
						}
					}
					products: product {
						productDescription {
							md: childMarkdownRemark {
								html
							}
						}
						productName
						productPrice
						priceRangeMax
						displayOrder
					}
				}
			}
		}
	}
`

export default Services
