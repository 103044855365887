import { useState, useEffect } from 'react'

const getWindowWidth = () =>
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

export function useWindowWidth() {
	// save current window width in the state object
	let [width, setWidth] = useState(getWindowWidth())

	// in this case useEffect will execute only once because
	// it does not have any dependencies.
	useEffect(() => {
		// timeoutId for debounce mechanism
		let timeoutId = null
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout(timeoutId)
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout(() => setWidth(getWindowWidth()), 150)
		}
		// set resize listener
		window.addEventListener('resize', resizeListener)

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener)
		}
	}, [])

	return width
}

export const useIsWindowResizing = () => {
	const [isResizing, setIsResizing] = useState(false)
	useEffect(() => {
		// timeoutId for debounce mechanism
		let timeoutId = null
		const resizeListener = () => {
			setIsResizing(true)
			// prevent execution of previous setTimeout
			clearTimeout(timeoutId)
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout(() => setIsResizing(false), 150)
		}
		// set resize listener
		window.addEventListener('resize', resizeListener)

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener)
		}
	}, [])

	return isResizing
}
