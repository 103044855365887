import React from 'react'
// import Girls from '../img/beach_break_team.jpg'
import SeaOats from '../img/beach_break_sea_oats.jpg'

const TeamMembers = () => {
	return (
		<div className="container container--wide team-members-container">
			<div className="bg-bar"></div>
			<div className="team-members-image-container">
				<img className="team-members img-fluid" src={SeaOats} alt="Beach Break Logo" />
				{/* <figcaption>The Beach Break Team</figcaption> */}
			</div>
		</div>
	)
}

export default TeamMembers
