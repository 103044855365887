import React, { useEffect } from 'react'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Policy = ({
	index,
	displayOrder,
	showPolicyTitle,
	policyTitle,
	policyDescription,
	inViewThreshold,
}) => {
	const controls = useAnimation()
	const { ref, inView } = useInView({
		threshold: inViewThreshold ? inViewThreshold : 1,
	})

	useEffect(() => {
		if (inView) {
			controls.start('visible')
		}
	}, [controls, inView])

	const policyVariant = {
		hidden: { opacity: 0, x: 100 },
		visible: { opacity: 1, x: 0 },
	}

	return (
		<motion.div
			ref={ref}
			className="policy"
			style={{ order: displayOrder }}
			variants={policyVariant}
			initial="hidden"
			animate={controls}
		>
			{showPolicyTitle && <motion.h3 className="policy__title">{policyTitle}</motion.h3>}
			<div
				className="policy__text"
				dangerouslySetInnerHTML={{ __html: policyDescription.md.html }}
			></div>
		</motion.div>
	)
}

export default Policy
