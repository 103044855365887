// import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'

// - Solid Icons {fas}
import {
	faMapMarkerAlt as faMapMarkerAlt_solid,
	faTimes as faTimes_solid,
} from '@fortawesome/pro-solid-svg-icons'

import {
	faYoutube as Youtube,
	faFacebook as Facebook,
	faTwitter as Twitter,
	faInstagram as Instagram,
} from '@fortawesome/free-brands-svg-icons'

// Add Imports To Library
library.add(faMapMarkerAlt_solid, faTimes_solid, Youtube, Facebook, Twitter, Instagram)

const FontAwesomeLibrary = library

//? <FontAwesomeIcon icon={{ prefix: 'fas', iconName: icon }} />

export default FontAwesomeLibrary
