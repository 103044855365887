import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FacbookIcon from '../img/Facebook-8.png'
import InstagramIcon from '../img/Instagram-8.png'
import YoutubeIcon from '../img/YouTube-8.png'

const SocialFollow = () => {
	return (
		<div className="container container--standard social-connect-container">
			<h3>Follow Us</h3>
			<div className="social-connect">
				<Link
					to="https://www.instagram.com/officialbeachbreaksalon/?hl=en"
					target="_blank"
					className="instagram social"
				>
					{/* <FontAwesomeIcon size="2x" icon={{ prefix: 'fab', iconName: 'instagram' }} /> */}
					<img className="img-fluid" src={InstagramIcon} alt="facebook icon" />
				</Link>
				<Link
					to="https://www.facebook.com/beach.breaksalon"
					target="_blank"
					className="facebook social"
				>
					{/* <FontAwesomeIcon size="2x" icon={{ prefix: 'fab', iconName: 'facebook' }} /> */}
					<img className="img-fluid" src={FacbookIcon} alt="facebook icon" />
				</Link>
				<Link
					to="https://www.youtube.com/channel/UCNjPmK__XXbP9thrEcHGLnA"
					target="_blank"
					className="youtube social"
				>
					{/* <FontAwesomeIcon size="2x" icon={{ prefix: 'fab', iconName: 'youtube' }} /> */}
					<img className="img-fluid" src={YoutubeIcon} alt="facebook icon" />
				</Link>
			</div>
		</div>
	)
}

export default SocialFollow
